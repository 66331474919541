import { Box, Button, Text } from "@chakra-ui/react";
import { useTelegram } from "contexts/telegram";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { formatToK } from "utils";
import { APIClient } from "../../api";
import { useAppContext } from "../../contexts";
import { DoneIcon, ToDoIcon } from "../../theme/components/icon";
import DepositTag from "./depositTag";
import { BiSolidPlusCircle } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";

const TaskTag = ({ data, index }) => {
  const {
    userToken,
    userInfo,
    userTaskList,
    fetchUserTaskList,
    fetchUser,
    isConnectWalletTaskClicked,
    setIsConnectWalletTaskClicked,
    isWatchAzeroClicked,
    setIsWatchAzeroClicked,
  } = useAppContext();
  const history = useHistory();
  const { tgWebApp } = useTelegram();
  const [isClicked, setIsClicked] = useState(false);
  const doTask = async (taskData) => {
    try {
      const { task_code, name, target } = taskData;
      if (task_code.includes("watch_youtube_video")) {
        history.push(`/youtubevideo/${task_code}`);
      } else if (task_code == "follow_twitter") {
        tgWebApp.openLink(`https://x.com/${target}`, { tryBrowser: true });
      } else if (task_code == "connect_twitter") {
        try {
          tgWebApp.openLink(
            `${process.env.REACT_APP_TWITTER_URL}/authenticate/twitter?telegram_id=${userInfo.id}`,
            { tryBrowser: true }
          );
        } catch (error) {
          console.log("ERROR:", error);
        }
      } else if (task_code == "follow_twitter") {
        tgWebApp.openLink(`https://x.com/${target}`, { tryBrowser: true });
      } else if (
        ["follow_twitter_link", "join_discord_group"].includes(task_code)
      ) {
        try {
          tgWebApp.openLink(target, { tryBrowser: true });
        } catch (error) {
          console.log("ERROR:", error);
        }
      } else if (
        [
          "join_telegram_group",
          "join_telegram_channel",
          "join_tele_group",
        ].includes(task_code)
      ) {
        tgWebApp.openTelegramLink(`https://t.me/${target.replace("@", "")}`);
      } else if (["telegram_wallet_connect"].includes(task_code)) {
        history.push("/level");
      } else if (task_code == "share_story") {
        tgWebApp.shareToStory(`https://app.cowboyduckie.com/${target}.png`, {
          text: "Join Cowboy Duckie",
        });
      } else if (task_code == "boost_group") {
        tgWebApp.openTelegramLink(`https://t.me/boost/${target}`);
      } else if (task_code == "join_telegram_app") {
        tgWebApp.openTelegramLink(target);
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };
  const doTaskMutation = useMutation(async (taskData) => {
    if (taskData?.task_code == "telegram_wallet_connect") {
      setIsConnectWalletTaskClicked(true);
    } else if (taskData?.task_code?.includes("watch_youtube_video")) {
      setIsWatchAzeroClicked(true);
    } else setIsClicked(true);
    await doTask(taskData);
  });
  const doCheckMutation = useMutation(async (taskData) => {
    const { task_code, name, target } = taskData;
    try {
      if (task_code == "connect_twitter") {
        const data = await fetchUserTaskList();
        if (
          data?.find((e) => e?.task_code == "connect_twitter")?.is_finished ==
          true
        )
          toast.success("Success");
        else toast.error("Verification failed!");
        await fetchUser();
      } else if (
        [
          "follow_twitter",
          "join_telegram_group",
          "join_telegram_channel",
          "invite_friend_task",
          "telegram_wallet_connect",
          "share_story",
          "boost_group",
          "join_telegram_app",
          "join_discord_group",
          "follow_twitter_link",
          "join_tele_group",
          "watch_youtube_video_azero",
        ].includes(task_code)
      ) {
        const resp2 = await APIClient.verifyTask(userToken, task_code, target);
        if (resp2?.success == true) toast.success("Success");
        else toast.error("Verification failed!");
        await fetchUserTaskList();
        await fetchUser();
      }
    } catch (error) {
      console.log(error);
      toast.error("Something Wrong", error);
    }
  });
  return (
    <Box
      sx={{
        display: "flex",
        borderRadius: "8px",
        border: "1.4px solid #587CA6",
        background: "linear-gradient(180deg, #F2F8FF 0%, #FEFAA5 51.43%)",
        mb: index < userTaskList?.length ? "10px" : 0,
        pr: "10px",
        alignItems: "center",
        ml: "20px",
        h: "48px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          w: "100%",
          h: "24px",
          bottom: 0,
          left: 0,
          background: "linear-gradient(0deg, #F6F061 16.67%, #E9E592 100%)",
          borderBottomRadius: "6px",
        }}
      ></Box>
      <Box
        sx={{
          borderRadius: "40px",
          // background: "#366CB6",
          // boxShadow: "0px -5px 10px 0px #1A06B3 inset",
          w: "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "2px solid rgba(26, 6, 179, 0.40)",
          ml: "-20px",
          py: "4px",
          background:
            "linear-gradient(180deg, #305EAF 0%, #131191 47.7%, #305EAF 100%)",
          gap: "2px",
          zIndex: 1,
        }}
      >
        <BiSolidPlusCircle color="#FFF" />
        <Text
          userSelect="none"
          sx={{
            color: "#FFF",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "normal",
            alignSelf: "center",
          }}
        >
          {formatToK(data?.point)}
        </Text>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          pl: "4px",
          alignItems: "flex-start",
          flexDirection: "column",
          justifyContent: "center",
          zIndex: 1,
        }}
        onClick={() => {
          if (!(data?.is_finished == true)) {
            doTaskMutation.mutate(data);
          }
        }}
      >
        <Text
          userSelect="none"
          sx={{
            color: "#00006D",
            fontSize: "12px",
            fontWeight: "700",
            lineHeight: "normal",
          }}
        >
          {data?.name}
        </Text>
      </Box>
      {data?.task_code == "telegram_wallet_connect" &&
        isConnectWalletTaskClicked &&
        !data?.is_finished && (
          <Button
            size="sm"
            isLoading={doCheckMutation.isLoading}
            onClick={() => {
              doCheckMutation.mutate(data);
            }}
          >
            <FaCheck />
          </Button>
        )}
      {data?.task_code?.includes("watch_youtube_video") &&
        isWatchAzeroClicked &&
        !data?.is_finished && (
          <Button
            size="sm"
            isLoading={doCheckMutation.isLoading}
            onClick={() => {
              doCheckMutation.mutate(data);
            }}
          >
            <FaCheck />
          </Button>
        )}
      {isClicked && !data?.is_finished && (
        <Button
          size="sm"
          isLoading={doCheckMutation.isLoading}
          onClick={() => {
            doCheckMutation.mutate(data);
          }}
        >
          <FaCheck />
        </Button>
      )}
    </Box>
  );
};

const TaskPage = () => {
  const { userToken, userInfo, fetchUserTaskList, userTaskList } =
    useAppContext();
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    fetchUserTaskList();
  }, []);

  const taskList = useMemo(() => {
    if (tabIndex == 0) {
      const lowestInviteTarget = Math.min(
        ...userTaskList
          ?.filter(
            (task) =>
              task.task_code == "invite_friend_task" && !task.is_finished
          )
          .map((task) => Number(task.target))
      );
      return userTaskList?.filter((e) => {
        if (e?.task_code == "invite_friend_task") {
          if (e?.target == lowestInviteTarget) return true;
          else return false;
        }
        return (
          e?.is_finished == false || e?.task_code == "make_ton_transaction"
        );
      });
    } else
      return userTaskList?.filter(
        (e) => e?.is_finished == true && e?.task_code != "make_ton_transaction"
      );
  }, [userTaskList, tabIndex]);
  return (
    <Box
      sx={{
        w: "100%",
        h: "100%",
        display: "flex",
        flexDirection: "column",
        p: "32px",
        justifyContent: "center",
        bg: "#4ED4DD",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          bg: "#DBECFF",
          borderRadius: "40px",
          boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Box
          h="6vh"
          sx={{
            borderRadius: "40px",
            background: "#00006D",
            border: "2px solid gray",
            boxShadow:
              "0px -10px 30px 0px #1A06B3 inset, 0px 10px 20px 0px rgba(0, 0, 0, 0.20)",
            display: "flex",
            w: "calc(100% + 20px)",
            marginLeft: "-10px",
            marginTop: "-10px",
          }}
        >
          {[
            {
              icon: <ToDoIcon w="3vh" />,
              name: "Tasks",
            },
            {
              icon: <DoneIcon w="3vh" />,
              name: "Done",
            },
          ].map((e, index) => {
            const isActive = index == tabIndex;
            return (
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  opacity: isActive ? 1 : 0.6,
                  boxShadow: isActive
                    ? "inset 0px -10px 30px 0px #1A06B3"
                    : null,
                  borderRadius: "40px",
                  transition: "all 0.2s ease-in-out",
                }}
                onClick={() => {
                  setTabIndex(index);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "center",
                    borderRadius: "40px",
                    // border: "2px solid rgba(26, 6, 179, 0.20)",
                    background: isActive
                      ? "linear-gradient(264deg, #325EB6 7.02%, #3056B5 60.71%, #2225B4 94.59%)"
                      : null,
                    p: "10px",
                    transition: "all 0.2s ease-in-out",
                  }}
                >
                  {e?.icon}
                  <Text
                    userSelect="none"
                    sx={{
                      color: "#FFF",
                      textShadow: "0px 3px 3px #162B8A",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                  >
                    {e?.name}
                  </Text>
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box
          maxH="55vh"
          overflowY="scroll"
          sx={{
            flex: 1,
            p: "10px",
          }}
        >
          {!(taskList?.length > 0) && (
            <Box sx={{ fontWeight: "bold", textAlign: "center", pt: "20px" }}>
              No tasks
            </Box>
          )}
          {taskList?.map((e, index) => {
            if (["make_ton_transaction"].includes(e?.task_code))
              return <DepositTag data={e} key={index} index={index} />;
            return (
              <TaskTag
                data={e}
                key={`${index}-${e?.task_code}-${e?.target}`}
                index={index}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default TaskPage;
